import AppBar from '../../components/AppBar/AppBar';
import NavBar from '../../components/navbar/NavBar';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { collection, onSnapshot, where, query, getDoc, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { AuthContext } from '../../context/AuthContext';
import './home.scss';
import Widget from '../../components/widget/Widget';
import Featured from '../../components/featured/Featured';
import Chart from '../../components/chart/Chart'

const Home = () => {

    const {currentUser} = useContext(AuthContext);

    // const [flag, setFlag] = useState(false);

    const [admin, setAdmin] = useState({});

    // useEffect(()=>{
    //     const fetchDriver = async () => {
    //     //     try {
    //     //         const docRef = doc(db, "countyadmins", currentUser.uid);
    //     //         const docSnap = await getDoc(docRef);
    //     //         if (docSnap.exists()) {
    //     //             setAdmin(docSnap.data());
    //     //             // setFlag(false);
    //     //             // console.log(admin);
    //     //         }
    //     //         else {
    //     //             console.log("No such document");
    //     //             // setFlag(true);
    //     //         }
    //     //     } catch (error) {
                
    //     //     }
    //         const unsub = onSnapshot(doc(db, "admins", currentUser.uid), (doc)=> {
    //             setAdmin(doc.data());
    //             // console.log(admin);
    //         },
    //         (error) => {
    //             console.log(error);
    //         }
    //         );
    //         return () => {unsub();};
    //     };
    //     fetchDriver();
        
    // },[currentUser]);

  return (
    <div className='flex'>
        <AppBar/>
        <NavBar/>
        <div className="mt-12 ml-8 justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 widgets lg:ml-60 mt-10">
                <Widget type="total"/>
                <Widget type="male"/>
                <Widget type="female"/>
                <Widget type="license"/>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 charts lg:ml-60 ml-0">
                <Featured/>
                <Chart title="Last 6 Months (Registered drivers)" aspect={2 / 1}/>
            </div>
        </div>
    </div>
  )
}

export default Home