import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import DriversList from './pages/list/DriversList';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import Single from './pages/Single/Single';
import Form from './components/modalform/Form';
import ForgotPassword from './pages/forgotpassword/ForgotPassword';
import { Profile } from './pages/profile/Profile';
import Statistics from './pages/statistics/Statistics';

function App() {

  const {currentUser} = useContext(AuthContext);

  const RequireAuth = ({children})=>{
    if(!currentUser){
      return <Navigate to="/login"/>
    }
    return children
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route path='login' element={<Login/>}/>
          <Route index element={<RequireAuth><Home/></RequireAuth>}/>
          <Route path='drivers'>
            <Route index element={<RequireAuth><DriversList/></RequireAuth>}/>
            <Route path='/drivers/:Id' element={<RequireAuth><Single/></RequireAuth>}/>
          </Route>
          <Route path='/profile' element={<RequireAuth><Profile/></RequireAuth>}/>
          {/* <Route path='signup' element={<Signup/>}/> */}
          <Route path='forgot-password' element={<ForgotPassword/>}/>
          <Route path='/statistics' element={<RequireAuth><Statistics/></RequireAuth>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
