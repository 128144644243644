import "./single.scss";
import NavBar from '../../components/navbar/NavBar';
import AppBar from '../../components/AppBar/AppBar';
// import Chart from "../../components/chart/Chart";
// import List from "../../components/table/Table";
import { Link, useLocation } from "react-router-dom";
import { React, useEffect, useState } from "react";
import { collection, doc, onSnapshot, getDoc } from "firebase/firestore";
import {db} from '../../firebase';
// import {ReactPDF, Image, Document, Page, Text, View, StyleSheet, PDFDownloadLink, pdf, PDFRenderer, PDFViewer } from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

import tukonlogo from "../../assets/tukonlogo2.jpeg"

const Single = () => {
  const path = window.location.pathname.split("/")[2];
  // console.log(path);

  const [data,setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      // let list = [];
      try {
        const docRef = doc(db, "drivers", path);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log("Document data:", docSnap.data());
          setData(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        // console.log(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  },[path]);


  // pdf stylesheet
//   const styles = StyleSheet.create({
//     page: {
//       flexDirection: 'row',
//       backgroundColor: '#E4E4E4'
//     },
//     section: {
//       margin: 10,
//       padding: 10,
//       flexGrow: 1
//     },
//     image: {
//       width: '50%',
//       padding: 10,
//     },
//   });

//   const MyDocument = () => (
//     <Document>
//       <Page size="A4" style={styles.page}>
//         <View style={styles.section}>
//           <Text>Section #1</Text>
//           <Image style={styles.image} src={data.image} />
//         </View>
//         <View style={styles.section}>
//           <Text>{data.driverfirstName}</Text>
//           <Text>{data.driverlastName}</Text>
//         </View>
//       </Page>
//     </Document>
//   );

  // const createpdf = () => {
  //   PDFViewer.render(<MyDocument />, 'example.pdf');
  // }

//   const Doc = () => (
//     <PDFViewer>
//       <MyDocument />
//     </PDFViewer>
//   );
  
//   const createpdf = async () => {
    // PDFViewer.render(<MyDocument />, 'example.pdf');
    // ReactDOM.render(<Doc />, document.getElementById('root'));
    // window.open(<Doc/>, '_blank');
//   }
  // ReactDOM.render(<Doc />, document.getElementById('root'));
  return (
    <div className="flex">
      <AppBar/>
      <NavBar/>
      <div className="singleContainer w-full list mt-12 ml-8">
        <div className="lg:ml-60 mt-10">
        <div className="top flex-col md:flex-row">
          <div className="left">
            {/* <div className="editButton">Edit</div> */}
            <h1 className="itemTitle title">Driver's Details</h1>
            <div className="item flex-col md:flex-row">
              <div>
                <img src={data.image} alt="" className="itemImg" />
              </div>
              <div className="details">
                {/* <h1 className="itemTitle">Driver's Details</h1> */}
                <div className="detailItem">
                  <span className="itemKey">First Name:</span>
                  <span className="itemValue">{data.driverfirstName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Last Name:</span>
                  <span className="itemValue">{data.driverlastName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">National ID:</span>
                  <span className="itemValue">{data.nationalID}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{data.driverPhone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Gender:</span>
                  <span className="itemValue">{data.driverGender}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Year of Birth:</span>
                  <span className="itemValue">{data.driverYearOfBirth}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Driver Type:</span>
                  <span className="itemValue">{data.driverType}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Driver's License:</span>
                  <span className="itemValue">{data.driverLicense}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Tuk Tuk Number Plate:</span>
                  <span className="itemValue">{data.tuktukPlateNo}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right left-5 md:left-0 top-0 md:top-14">
            <div className="item">
              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">County:</span>
                  <span className="itemValue">{data.county}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Sub County:</span>
                  <span className="itemValue">{data.subCounty}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Ward:</span>
                  <span className="itemValue">{data.ward}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Stage:</span>
                  <span className="itemValue">{data.stage}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Group:</span>
                  <span className="itemValue">{data.groupname}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Tuk Tuk Owner:</span>
                  <span className="itemValue">{data.tuktukOwner}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Tuk Tuk Owner Phone Number:</span>
                  <span className="itemValue">{data.tuktukOwnerPhoneNumber}</span>
                </div>
                {/* <div className="mt-5">
                    <button className="bg-black text-white py-1 px-2" onClick={createpdf}>Export</button>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="right">
            <Chart aspect={3 / 1} title="User Spending (Last 6 Months)"/>
          </div> */}
        </div>
        {/* <div className="bottom">
        <h1 className="title">Last Transactions</h1>
          <List/>
        </div> */}
      </div>
    </div>
    </div>
  )
}

export default Single