import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCYvVHvjJZi85Yiwb6twS9ZSBX8t8TPuRw",
  authDomain: "tukon-a1e34.firebaseapp.com",
  projectId: "tukon-a1e34",
  storageBucket: "tukon-a1e34.appspot.com",
  messagingSenderId: "991761113922",
  appId: "1:991761113922:web:ece54c7c65ab83bdb79edb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);