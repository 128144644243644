import AppBar from "../../components/AppBar/AppBar";
import DriversDatatable from "../../components/datatable/DriversDatatable";
import NavBar from "../../components/navbar/NavBar";

const DriversList = () => {
  return (
    <div className="flex">
        <AppBar/>
        <NavBar/>
        <div className="w-full list mt-12">
            <div className="lg:ml-64 ml-0 mt-10">
                <DriversDatatable/>
            </div>
        </div>
    </div>
  )
}

export default DriversList