import { useState } from "react";
import "./forgotpassword.scss";
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from "firebase/auth";
import {Link} from "react-router-dom";


const ForgotPassword = () => {

    const [email, setEmail] = useState("");
    const [flag, setFlag] = useState(false);
    const [error, setError] = useState("");

    const handleReset = (e) => {
        e.preventDefault();
        setError("");
        if(email === "" || email === undefined) return setError("Please enter a valid email");
        sendPasswordResetEmail(auth, email)
        .then(()=> {
            setFlag(true);
        }).catch((error)=>{
            setError("");
            switch (error.code) {
                case 'auth/user-not-found':
                    setError("User not found");
                    break;
                case 'auth/invalid-email':
                    setError("Invalid email");
                    break;
                default:
                    break;
            }
        })
    }

  return (
    <>
        <div className='login' style={{display: !flag ? "flex" : "none"}}>
            <div className="login-cont">
                <form onSubmit={handleReset}>
                    <h2>PASSWORD RESET</h2>
                    <input type="email" className='shadow' placeholder='Email' onChange={e=>setEmail(e.target.value)}/>
                    <button type='submit'>Send Reset Email</button>
                    {error && <span>{error}</span>}
                </form>
            </div>
        </div>

        <div className="login" style={{display: flag ? "flex" : "none"}}>
            <div className="login-cont">
                <form>
                    <h1 className="text-center">Password Reset Link has been sent {email}</h1>
                    <Link to="/login">
                        <button>Go to Login Page</button>
                    </Link>
                </form>
                
            </div>
        </div>
    </>
  )
}

export default ForgotPassword