import { useState } from 'react';
import logo from "../../assets/tukonlogolarge.jpeg";
import "./login.scss";
import {useNavigate, Link} from "react-router-dom";
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';

const Login = () => {

  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const {dispatch} = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    setError("");
    if(email === "" || email === undefined) return setError("Please enter a valid email");
    signInWithEmailAndPassword(auth, email, password)
    .then(()=> {
        // userCredential
        // const user = userCredential.user;
        // dispatch({type:"LOGIN", payload:user});
        navigate("/");
    })
    .catch((error)=>{
        setError("");
        switch (error.code) {
            case 'auth/user-not-found':
                setError("User not found");
                break;
            case 'auth/invalid-email':
                setError("Invalid email");
                break;
            default:
                break;
        }
        // if(error.code === `auth/user-not-found`) return setError("User not found");
        // console.log(error);
    });
  }

  return (
    <>
        <div className='login'>
            <div className="login-cont">
                <form onSubmit={handleLogin}>
                    <img src={logo} alt="" className='logo' />
                    <h2>LOG IN</h2>
                    <input type="email" className='shadow' placeholder='Email' onChange={e=>setEmail(e.target.value)}/>
                    <input type="password" className='shadow' placeholder='Password' onChange={e=>setPassword(e.target.value)}/>
                    <button type='submit'>Login</button>
                    {error && <span>{error}</span>}
                    <Link to="/forgot-password" >
                        <p className="text-blue-600">Forgot password</p>
                    </Link>
                </form>
            </div>
        </div>
    </>
  )
}

export default Login