export const userColumns =[
    { field: 'id', headerName: 'ID', width: 70 },{
    field:"user", headerName:"Photo", width: 80, renderCell: (params) => {
        return (
            <div className="cellWithImg">
                <img className="cellImg" src={params.row.image} alt="avatar"/>
                {params.row.driversname}
            </div>
        );
    },
},
{
    field: "driverfirstName", headerName:"First Name", width: 100,
},
{
    field: "driverlastName", headerName:"Last Name", width: 100,
},
{
    field: "nationalID",
    headerName: "Driver's ID",
    width: 100,
},
{
    field: "driverPhone",
    headerName: "Phone Number",
    width: 100,
},
{
    field: "groupname",
    headerName: "Group",
    width: 100,
},
{
    field: "county",
    headerName: "County",
    width: 100,
},
{
    field: "tuktukPlateNo",
    headerName: "TukTuk Plate Number",
    width: 130,
}

];